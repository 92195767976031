import * as protomaps from 'protomaps';
import { memo, useEffect } from 'react';
import { useMap } from 'react-leaflet';
import { useAppSelector } from '../../../App/store';
import usePLU from '../../../shared/hooks/usePLU';
import { getMapState } from '../../map/mapSlice';
import { MyPaintSymbolizerPlu } from './methods';
import { PolygonLabelSymbolizer } from './symbolizer/symbolizer';
import { Map } from 'leaflet';

function protomapsLeafletLayer({
  layerName,
  p,
  map,
}: {
  layerName: string;
  p: protomaps.PMTiles;
  map: Map;
}) {
  const layerId = `plu_env_${layerName}`;

  // Check if a layer with the same ID already exists
  let layerExists = false;
  map.eachLayer((l: any) => {
    if (l.options.id === layerId) {
      layerExists = true;
    }
  });

  // Add the layer if it doesn't exist yet
  if (!layerExists) {
    const layer = protomaps.leafletLayer({
      paint_rules: [
        {
          dataLayer: layerName,
          symbolizer: new MyPaintSymbolizerPlu(),
          minzoom: 0,
          maxzoom: 22,
        },
      ],
      label_rules: [
        {
          dataLayer: layerName,
          symbolizer: new PolygonLabelSymbolizer({
            label_props: (zoom: any, feature: any) => {
              feature.props.zone_name =
                feature.props.nom ??
                `${feature.props.zone ?? ''}${
                  feature.props.zone_child ? `-${feature.props.zone_child}` : ''
                }`;
              return ['zone_name'];
            },
            fill: 'white',
            width: 2,
            stroke: 'red',
            font: '600 20px sans-serif',
          }),
          minzoom: 13,
          maxzoom: 22,
        },
      ],

      attribution: '',
      url: p.source.getKey(),
    });

    layer.options.layer_group = 'plu';
    layer.options.layer_pmtiles_group = 'plu_pmtiles';
    layer.options.id = layerId;
    layer.options.zIndex = 3;
    layer.addTo(map);
  }
}

function PmTilesPlu() {
  const map = useMap();
  const { pluDisplayed, pluPmtiles } = usePLU();
  const { geolocDatas } = useAppSelector(getMapState);

  const resetLayer = () => {
    if (!pluDisplayed)
      map.eachLayer((l: any) => {
        if (l.options.layer_group === 'plu') {
          map.removeLayer(l);
        }
      });
  };

  useEffect(() => {
    if (geolocDatas?.inseeCode && pluDisplayed && pluPmtiles) {
      const inseeCode = geolocDatas.inseeCode;
      // reset layer if an city allready displayed
      resetLayer();

      const p = new protomaps.PMTiles(pluPmtiles);

      p.getMetadata().then(
        (m: any) => {
          if (m.tilestats.layers.length > 1) {
            m.tilestats.layers.map((elt: any) => {
              protomapsLeafletLayer({
                layerName: elt.layer,
                p,
                map,
              });
            });
          } else {
            protomapsLeafletLayer({
              layerName: inseeCode,
              p,
              map,
            });
          }
        },
        (err: any) => console.log('err', err)
      );
    } else {
      // reset layer if no insee code
      resetLayer();
    }
  }, [geolocDatas?.inseeCode, pluDisplayed]);

  return null;
}

export default memo(PmTilesPlu);
